// 标题
const titleData = [["生产总览", "巡检信息", "告警信息"]];

// 用户状态
const personState = [
  { label: "全部用户状态", value: undefined },
  { label: "禁用", value: "0" },
  { label: "启用", value: "1" },
];
// 组织类型
const patternOrganization = [
  // { label: "供应商企业", value: "SUPPLIER" },
  { label: "运维团队", value: "OPERATION" },
  { label: "客户企业", value: "CUSTOMER" },
];

// 巡检状态
const InspectionStatus = [
  { label: "全部", value: undefined },
  { label: "超时巡检", value: "TIMEOUT" },
  { label: "待巡检", value: "WAIT" },
  { label: "距离下次巡检倒计时", value: "PLAN" },
  { label: "已完成", value: "FINISH" },
];

// 运维工单
const ops = [
  { label: "全部", value: undefined },
  { label: "待派单", value: "WAIT_DISPATCH" },
  { label: "待维护", value: "WAIT_PRESERVE" },
  { label: "待复核", value: "WAIT_RE_CHECK" },
  { label: "审核不通过", value: "AUDIT_REJECT" },
  { label: "已完成", value: "FINISH" },
];

// 项目类型

const projectType = [
  { label: "ZJ", value: "ZJ" },
  { label: "WT", value: "WT" },
];

// 储能
const stored = [
  { label: "是", value: "YES" },
  { label: "否", value: "NO" },
];

// 告警等级
const AlarmList = [
  { label: "全部", value: undefined },
  { label: "一般", value: "BLUE_10" },
  { label: "重要", value: "LEVEL_20" },
  { label: "严重", value: "LEVEL_30" },
];
// 告警状态
const statusList = [
  { label: "全部", value: undefined },
  { label: "待解决", value: "0" },
  { label: "已解决", value: "1" },
  { label: "超时", value: "3" },
];

// 模拟路由

const routers = [
  {
    path: "/ping-tai/",
    name: "main",
    redirect: "/ping-tai/home/main",
    component: "largeS/index",
    children: [
      {
        path: "/ping-tai/home/main",
        name: "home",
        redirect: "/ping-tai/home/main",
        component: "largeS/home/index",
        meta: { title: "首页" },
        children: [
          {
            path: "/ping-tai/home/main",
            name: "/qianKun/ping-tai/home-main",
            meta: { pathShow: true },
            component: "largeS/home/main/index",
          },
          // {
          //   path: "/qianKun/ping-tai/home/main/device-backup",
          //   name: "/qianKun/ping-tai/device-backup",
          //   component: "largeS/home/main/device-backup/index",
          // },
          // {
          //   path: "/qianKun/ping-tai/home/main/device-backup/hisAna",
          //   name: "hisAna",
          //   component:
          //     "largeS/home/main/device-backup/right-one/history-analysis/index",
          // },
        ],
      },
      // {
      //   path: "/qianKun/ping-tai/product-manage",
      //   name: "/qianKun/ping-tai/product-manage",
      //   meta: { title: "生产管理" },
      //   component: "largeS/product-manage/index",
      // },
      // {
      //   path: "/qianKun/ping-tai/health",
      //   name: "health",
      //   redirect: "/health/abnormal",
      //   meta: { title: "健康监控" },
      //   component: "largeS/health/index",
      //   children: [
      //     {
      //       path: "/qianKun/ping-tai/health/abnormal",
      //       name: "abnormal",
      //       meta: { title: "异常告警" },
      //       component: "largeS/health/abnormal/index",
      //       children: [
      //         // 公共的健康监控全部记录详情界面
      //         {
      //           path: "/qianKun/ping-tai/health/comRecord",
      //           name: "comRecord",
      //           component:
      //             "largeS/health/abnormal/comRecord/index",
      //         },
      //       ],
      //     },
      //     {
      //       path: "/qianKun/ping-tai/health/replace",
      //       name: "replace",
      //       meta: { title: "换件维修" },
      //       component: "largeS/health/replace/index",
      //     },
      //     {
      //       path: "/qianKun/ping-tai/health/inspect-main",
      //       name: "inspect-main",
      //       meta: { title: "巡检运维" },
      //       component: "largeS/health/inspect-main/index",
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   path: "/qianKun/ping-tai/system/carM",
  //   name: "carM",
  //   component: "system/carM/index",
  // },
  // {
  //   path: "/qianKun/ping-tai/system/classM",
  //   name: "classM",
  //   component: "system/classM/index",
  // },
];
export { titleData, routers };
