import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// // 设备类型列表
// export const getDeviceList = (data: any) => {
//   return request({
//     url: "/factory/deviceType/list",
//     method: "get",
//   });
// };

// // 设备类型新增
// export const postDeviceAdd = (data: any) => {
//   return request({
//     url: "/factory/deviceType",
//     method: "post",
//     data: data,
//   });
// };

// // 设备类型修改
// export const putDeviceEdit = (data: any) => {
//   return request({
//     url: "/factory/deviceType",
//     method: "put",
//     data: data,
//   });
// };

// 新新新新

// 查询设备信息列表
export function listDevice(query: any) {
  return request({
    url: "/factory/device/list",
    method: "get",
    params: query,
  });
}

// 查询设备信息详细
export function getDevice(data: any) {
  return request({
    url: `/factory/device/${data.id}`,
    method: "get",
  });
}

// 新增设备信息
export function addDevice(data: any) {
  return request({
    url: "/factory/device",
    method: "post",
    data: data,
  });
}

// 修改设备信息
export function updateDevice(data: any) {
  return request({
    url: "/factory/device",
    method: "put",
    data: data,
  });
}

// 删除设备信息
export function delDevice(data: any) {
  return request({
    url: "/factory/device/removes",
    method: "delete",
    data: data,
  });
}
// 修改设备状态
export function updateStatus(data: any) {
  return request({
    url: "/factory/device/status",
    method: "put",
    data: data,
  });
}

// 下拉框列表
export function deviceSelectList(data: any) {
  return request({
    url: "/factory/device/selectList",
    method: "get",
    params: data,
  });
}

// 生产监控设备列表
export function deviceMonitorList(query: any) {
  return request({
    url: "/factory/productionMonitorDevice/deviceMonitor",
    method: "get",
    params: query,
  });
}
