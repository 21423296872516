import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 生产监控车间
export function getWorkshop(query: any) {
  return request({
    url: "/factory/productionMonitorWorkSpace/workSpaceMonitor",
    method: "get",
    params: query,
  });
}
// 生产监控车间-查看设备生产信息
export function getDevicePlanById(query: any) {
  return request({
    url: "/factory/productionMonitorWorkSpace/devicePlanById",
    method: "get",
    params: query,
  });
}
