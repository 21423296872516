import * as echarts from "echarts";

// 车间备份——生产总览
const annularFull = (value: any) => {
  value = value;
  let title = "实时产量(件)";
  let int = value.toFixed(2).split(".")[0];
  let float = value.toFixed(2).split(".")[1];
  return {
    backgroundColor: "",
    title: {
      text: "{a|" + int + "}{b|." + float + "}\n{c|" + title + "}",
      x: "center",
      y: "center",
      textStyle: {
        rich: {
          a: {
            fontSize: 18,
            color: "#fff",
            fontWeight: "600",
          },
          b: {
            fontSize: 10,
            color: "#fff",
            padding: [0, 0, 0, 0],
          },
          c: {
            fontSize: 10,
            color: "#fff",
            padding: [5, 0],
          },
        },
      },
    },
    series: [
      {
        type: "gauge",
        radius: "90%",
        clockwise: false,
        startAngle: "90",
        endAngle: "-269.9999",
        splitNumber: 30,
        detail: {
          offsetCenter: [0, -20],
          formatter: " ",
        },
        pointer: {
          show: false,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: [
              [0, "#2CFAFC"],
              [36.7 / 100, "#0ff"],
              [1, "#0f232e"],
            ],
            width: 40,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          length: 0,
          lineStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 22, 75, 0.62)",
            shadowOffsetY: "0",
            color: "rgba(0, 22, 75, 0.62)",
            width: 20,
          },
        },
        axisLabel: {
          show: false,
        },
      },
      {
        type: "pie",
        radius: ["74%", "75%"],
        hoverAnimation: false,
        clockWise: false,
        itemStyle: {
          normal: {
            color: "#0C355E",
          },
        },
        label: {
          show: false,
        },
        data: _dashed(),
      },

      {
        type: "pie",
        radius: [0, "50%"],
        hoverAnimation: false,
        clockWise: false,
        itemStyle: {
          normal: {
            shadowBlur: 20,
            shadowColor: "#000",
            color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
              {
                offset: 0,
                color: "#0FF",
              },
              {
                offset: 1,
                color: "#060f20",
              },
            ]),
          },
        },
        label: {
          show: false,
        },
        data: [100],
      },
      {
        type: "pie",
        radius: ["64%", "65.5%"],
        hoverAnimation: false,
        clockWise: false,
        itemStyle: {
          normal: {
            shadowBlur: 20,
            shadowColor: "rgba(0, 255, 255,.3)",
            color: "#0f232e",
          },
        },
        label: {
          show: false,
        },
        data: [100],
      },
      {
        type: "pie",
        radius: ["68%", "69.5%"],
        hoverAnimation: false,
        clockWise: false,
        itemStyle: {
          normal: {
            shadowBlur: 20,
            shadowColor: "rgba(0, 255, 255,.3)",
            color: "rgba(15, 35, 46,.6)",
          },
        },
        label: {
          show: false,
        },
        data: [100],
      },
    ],
  };
};

function _dashed() {
  let dataArr = [];
  for (var i = 0; i < 100; i++) {
    if (i % 2 === 0) {
      dataArr.push({
        name: (i + 1).toString(),
        value: 20,
        itemStyle: {
          normal: {
            color: "rgb(0,255,255,.3)",
          },
        },
      });
    } else {
      dataArr.push({
        name: (i + 1).toString(),
        value: 20,
        itemStyle: {
          normal: {
            color: "rgb(0,0,0,0)",
            borderWidth: 3,
            borderColor: "rgba(0,255,255,1)",
          },
        },
      });
    }
  }
  return dataArr;
}

// function doing() {
//   let option = myChart.getOption();
//   option.series[1].startAngle = option.series[1].startAngle - 1;
//   myChart.setOption(option);
// }
// function startTimer() {
//   timer = setInterval(doing, 100);
// }
// setTimeout(startTimer, 0);

// 设备备份——模压温度监控备份
const moldingTemp2 = () => {
  return {
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%",
    },
    series: [
      {
        name: "业务指标",
        type: "gauge",
        // 中间数据
        detail: {
          formatter: "{value}℃ 舒适",
          textStyle: {
            fontSize: 14,
          },
          color: "#fff",
        },
        axisLine: {
          // 坐标轴线
          lineStyle: {
            // 属性lineStyle控制线条样式
            width: 2,
            color: [
              [0.45, "#2e95f7"],
              [0.6, "#5abf39"],
              [1, "#f6aa3f"],
            ],
          },
          show: true,
        },
        axisTick: {
          show: false,
          splitNumber: 4,
        },
        axisLabel: {
          show: true,
          distance: 1,
        },
        splitLine: {
          show: true,
          length: 2,
          lineStyle: {
            color: "auto",
          },
        },
        // 指针样式
        pointer: {
          show: true,
          length: "50%",
          width: "6",
        },
        itemStyle: {
          color: "#00C8FF",
        },
        radius: "100%", // 仪表盘的在父级中所占的百分比 类似大小
        startAngle: 180, // 左侧角度
        endAngle: 0, // 右侧角度
        min: 0,
        max: 40,
        animation: true,
        data: [{ value: 10 }],
      },
    ],
  };
};
const moldingTemp = (name: any | "暂无") => {
  return {
    series: {
      type: "gauge",
      splitNumber: 28, //刻度数量
      min: 0,
      // max: 100,
      radius: "100%", // 仪表盘的在父级中所占的百分比 类似大小
      startAngle: 180, // 左侧角度
      endAngle: 0, // 右侧角度
      center: ["50%", "50%"],
      axisLine: {
        show: true,
        lineStyle: {
          width: 0,
          shadowBlur: 1,
          color: [
            [0, "#1683F2"],
            [0.3, "#1683F2"],
            [0.5, "#1683F2"],
            [0.8, "#1683F2"],
            [1, "#E02020 "],
          ],
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: "auto",
          width: 0.5,
        },
        length: 8,
        splitNumber: 1,
      },
      splitLine: {
        show: true,
        length: 2,
        lineStyle: {
          color: "auto",
        },
      },
      axisLabel: {
        show: false,
      },
      pointer: {
        //仪表盘指针
        //show: 0,
        length: "70%",
        width: 4,
      },
      detail: {
        // borderColor: "#fff",
        // shadowColor: "#fff", //默认透明
        //shadowBlur: 2,
        offsetCenter: [0, "40%"], // x, y，单位px
        textStyle: {
          // 其余属性默认使用全局文本样式，详见TEXTSTYLE
          color: "#fff",
          fontSize: 14,
        },
        // formatter: `{value}℃ \n ${name}`,
      },

      data: [],
    },
  };
};

// 稼动率进度条
const progressBar = () => {
  return {
    xAxis: {
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    grid: {
      containLabel: true,
      // left: 30,
      top: 0,
      // right: 40,
      bottom: 0,
      left: 2,
      right: 2,
    },
    yAxis: [
      {
        inverse: true,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        data: [100],
      },
    ],
    series: [
      {
        //内
        type: "bar",
        barWidth: 18,
        legendHoverLink: false,
        symbolRepeat: true,
        silent: true,
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#00abee", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#62E6F6", // 100% 处的颜色
              },
            ],
          },
        },
        label: {
          normal: {
            formatter: "头部",
            textStyle: {
              color: "#fff",
              fontSize: 14,
            },

            position: "left",
            distance: 1, // 向右偏移位置
            show: false,
          },
        },
        data: [80],
        z: 1,
        animationEasing: "elasticOut",
      },
      {
        // 背景
        type: "pictorialBar",
        animationDuration: 0,
        symbolRepeat: "fixed",
        symbolMargin: "20%",
        symbol: "roundRect",
        symbolSize: [6, 18],
        itemStyle: {
          normal: {
            // color: "#12272A",
          },
        },
        label: {
          normal: {
            formatter: "My-",
            textStyle: {
              color: "#fff",
              fontSize: 14,
            },
            position: "right",
            distance: 10, // 向右偏移位置
            show: false,
          },
        },
        data: [100],
        z: 0,
        animationEasing: "elasticOut",
      },
      {
        //分隔
        type: "pictorialBar",
        itemStyle: {
          color: "#000",
        },
        symbolRepeat: "fixed",
        symbolMargin: 4,
        symbol: "roundRect",
        symbolClip: true,
        symbolSize: [2, 18],
        symbolPosition: "start",
        symbolOffset: [0, 0],
        data: [0],
        z: 2,
        animationEasing: "elasticOut",
      },
    ],
  };
};

export { annularFull, moldingTemp, progressBar };
