const getLine = (AllList: any, Arr: any, list: any) => {
  AllList.forEach((item: any) => {
    Arr.forEach((it: any, i: any) => {
      if (item.valueName == it) {
        item.value = list[it] || 0;
        Arr.splice(i, 1);
        return;
      }
    });
  });
  return AllList;
};

export default getLine;
